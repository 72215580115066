import React from "react"
import { graphql } from "gatsby"
import ImgSections from "../components/img-sections"
import { withPreview } from "gatsby-source-prismic"
import { PageTitleHeading } from "../components/page-title-heading"

import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
  query OnlineLessonDetailPageQuery($uid: String!) {
    prismicOnlineLesson(uid: { eq: $uid }) {
      data {
        title {
          text
        }
        body {
          ... on PrismicOnlineLessonBodyContents {
            primary {
              text {
                html
              }
              section_img {
                alt
                fluid(maxWidth: 400) {
                  ...GatsbyPrismicImageFluid
                }
              }
              heading {
                text
              }
            }
          }
        }
      }
    }
  }
`
const Page = ({ data }) => {
  const pageData = data.prismicOnlineLesson.data
  const pageTitle = pageData.title.text
  let sections = pageData.body.map(section => {
    return {
      heading: section.primary.heading.text,
      img: {
        fluid: section.primary.section_img.fluid || null,
        alt: section.primary.section_img.alt || "",
      },
      text: section.primary.text.html,
    }
  })

  const breadPaths = [
    {
      text: pageTitle,
    },
  ]

  return (
    <Layout breadPaths={breadPaths}>
      <SEO title={pageTitle} />
      <PageTitleHeading>ドイツ美大受験準備\nオンラインクラス</PageTitleHeading>
      <ImgSections sectionData={sections} />
    </Layout>
  )
}

export default withPreview(Page)
