import React from "react"

const PageTitleHeading = ({ children }) => {
  var title = children.replace(/\\n/g, '<br class="block lg:hidden" />')
  return (
    <h1
      className="text-3xl md:text-4xl lg:text-5xl leading-tight font-bold py-2gap lg:py-1col"
      dangerouslySetInnerHTML={{
        __html: title,
      }}
    ></h1>
  )
}
export { PageTitleHeading }
